import React from 'react'
import styled, { css } from 'styled-components'
import { useInView } from 'react-intersection-observer'

import { media } from '../../utils/media'
import { space, fontSizes, colors } from '../../utils/tokens'
import GoogleMapEmbed from '../GoogleMapEmbed'

const SectionTitle = styled.h3`
  font-size: ${fontSizes[4]};
  margin-bottom: ${space[2]}px;
  font-weight: bold;
  text-align: center;
  color: ${colors.white};

  ${media.desktop`
    text-align: center;
  `}
`

const SectionSubTitle = styled.p`
  color: ${colors.white};
  font-size: 1.1rem;
  font-weight: normal;
  text-align: center;
  margin-bottom: 1rem;
  ${media.desktop`
    text-align: center;
    margin-bottom: 2rem;
  `}
`

const OfficeName = styled.h4`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`

const Address = styled.address`
  display: inline-block;
  margin-bottom: 1rem;
`

const LocationsSection = (props) => {
  const [ref, inView] = useInView({
    /* Optional options */
    triggerOnce: true
  })

  return (
    <section className={props.className}>
      <div
        css={css`
          padding: ${space[5]}px 0;
          ${media.desktop`
            max-width: ${props => props.theme.sizes.maxWidth};
            margin: auto;
          `}
        `}
      >
        <SectionTitle>Our New York Offices</SectionTitle>
        <SectionSubTitle>
          We have offices in Brooklyn and Queens, NYC.
          <br />
          Please give us a call before visiting any of our offices.
        </SectionSubTitle>
      </div>
      <div
        ref={ref}
        css={css`
          margin-top: 2rem;
          display: grid;
          grid-gap: 2rem;
          padding: 0 1rem;

          ${media.desktop`
            grid-template-columns: 1fr 1fr;
            max-width: ${props => props.theme.sizes.maxWidth};
            margin: auto;
            grid-gap: 2rem;
        `}
        `}
      >
        <section
          css={css`
            background-color: white;
            box-shadow: 0 5px 16px rgba(0, 0, 0, 0.1);
            padding: 2rem;
            border-radius: 4px;
          `}
        >
          <OfficeName>Williamsburg Office</OfficeName>
          <Address>252 Driggs Ave, Brooklyn, NY 11222</Address>
          <p>By appointment only.</p>
          {inView && (
            <GoogleMapEmbed
              id="gmap_canvas_queens"
              title="Google Map of PrintAWorld Factory"
              src="https://maps.google.com/maps?q=252%20Driggs%20Ave,%20Brooklyn,%20NY%2011222&t=&z=13&ie=UTF8&iwloc=&output=embed"
            />
          )}
        </section>
        <section
          css={css`
            background-color: white;
            box-shadow: 0 5px 16px rgba(0, 0, 0, 0.1);
            padding: 2rem;
            border-radius: 4px;
          `}
        >
          <OfficeName>Queens Office</OfficeName>
          <Address>74-12 88th St, Glendale, NYC 11385</Address>
          <p>By appointment only.</p>
          {inView && (
            <GoogleMapEmbed
              id="gmap_canvas_queens"
              title="Google Map of PrintAWorld Factory"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3024.467301527987!2d-73.8655169845952!3d40.70772827933233!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25fe1a7f49f8d%3A0xf0bcf2f2f7f71d4!2sPrintAWorld%20Factory!5e0!3m2!1sen!2sus!4v1567036070809!5m2!1sen!2sus"
            />
          )}
        </section>
        <section
          css={css`
            background-color: white;
            box-shadow: 0 5px 16px rgba(0, 0, 0, 0.1);
            padding: 2rem;
            border-radius: 4px;
          `}
        >
          <OfficeName>Brooklyn Office</OfficeName>
          <Address>394 McGuinness Blvd #302, Brooklyn, NYC 11222</Address>
          {inView && (
            <GoogleMapEmbed
              id="gmap_canvas_brooklyn"
              title="Google Map of PrintAWorld"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3023.1596481449897!2d-73.95454018419387!3d40.736512479329164!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2593be7da31a7%3A0x894d37cca18ef165!2sPrintAWorld!5e0!3m2!1sen!2sua!4v1565358703290!5m2!1sen!2sua"
            />
          )}
        </section>
      </div>
    </section>
  )
}

export default LocationsSection