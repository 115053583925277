import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { css } from 'styled-components'

import { breakpoints, space, mediaQueries, fontSizes, boxShadows } from '../../utils/tokens'

const ApplicationSection = (props) => {
  const { section, className, ...rest } = props
  return (
    <section
      className={className}
      {...rest}
    >
      <div
        css={css({
          maxWidth: breakpoints.xl,
          margin: `auto`,
        })}
      >
        <h3
          css={css({
            fontSize: fontSizes[3],
            fontWeight: `bold`,
            marginBottom: `${space[3]}px`,
          })}
        >
          {section.title}
        </h3>
        {section.body && (
          <div
            dangerouslySetInnerHTML={{
              __html: section.body.childMarkdownRemark.html,
            }}
            css={css({
              lineHeight: `1.8`,
              marginBottom: `${space[4]}px`,
              [mediaQueries.md]: {
                maxWidth: breakpoints.md,
              },
            })}
          />
        )}
        <ul
          css={css({
            margin: `0`,
            listStyle: `none`,
            [mediaQueries.md]: {
              display: `flex`,
            },
          })}
        >
          {section.cards.map(card => {
            return (
              <li
                key={card.id}
                css={css({
                  width: `100%`,
                  marginBottom: `${space[4]}px`,
                  padding: `${space[3]}px`,
                  boxShadow: boxShadows.card,
                  borderRadius: `4px`,
                  [mediaQueries.md]: {
                    width: `calc(33.3% - ${space[4]}px)`,
                    margin: `0 ${space[3]}px`,
                    ':first-child': {
                      marginLeft: 0,
                    }
                  },
                })}
              >
                <h5
                  css={css({
                    fontSize: fontSizes[2],
                    fontWeight: `bold`,
                    textAlign: `center`,
                    opacity: 0.8,
                    padding: `${space[3]}px 0 ${space[2]}px 0`,
                  })}
                >
                  {card.title}
                </h5>
                <p
                  css={css({
                    marginBottom: `${space[3]}px`,
                    textAlign: `center`,
                    fontStyle: `italic`,
                  })}
                >
                  {card.subtitle}
                </p>
                {card.media && (
                  <Img fluid={card.media.fluid} alt={card.media.title} />
                )}
              </li>
            )
          })}
        </ul>
      </div>
    </section>
  )
}

ApplicationSection.propTypes = {
  section: PropTypes.shape({
    body: PropTypes.object,
    title: PropTypes.string,
    cards: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      media: PropTypes.object
    }))
  })
}

export default ApplicationSection