import React from 'react'
import { css } from 'styled-components'

import {
  space,
  mediaQueries,
  breakpoints,
  fontSizes,
  boxShadows,
  colors,
} from '../../utils/tokens'
import Container from '../Container'
import { Link } from 'gatsby'

export const GridSection = ({ section }) => {
  return (
    <section
      css={css({
        padding: `${space[5]}px ${space[3]}px`,
        [mediaQueries.lg]: {
          maxWidth: breakpoints.xxl,
          margin: `auto`,
        },
        [mediaQueries.xxl]: {
          padding: `${space[5]}px 0`,
        },
      })}
    >
      <Container
      >
        <h2
          css={css({
            fontWeight: `bold`,
            fontSize: fontSizes[3],
            marginBottom: `${space[4]}px`,
            textAlign: `center`,
            maxWidth: breakpoints.md,
            marginLeft: `auto`,
            marginRight: `auto`,
            [mediaQueries.lg]: {
              fontSize: fontSizes[4],
            },
          })}
        >
          {section.title}
        </h2>
        {section.cards && (
          <ul
            css={css({
              listStyle: `none`,
              margin: 0,
              display: `grid`,
              gridGap: `${space[4]}px`,
              [mediaQueries.lg]: {
                gridTemplateColumns: `1fr 1fr 1fr`,
              },
              [mediaQueries.xxl]: {
                gridTemplateColumns: `1fr 1fr 1fr 1fr`,
              },
            })}
          >
            {section.cards.map(card => {
              return (
                <li
                  key={card.id}
                  css={css({
                    boxShadow: boxShadows.card,
                    backgroundColor: colors.white,
                    borderRadius: `4px`,
                    border: `1px solid ${colors.lightGray}`,
                    transitionDuration: `300ms`,
                    '&:hover': {
                      backgroundColor: colors.lightBase,
                      transitionDuration: `300ms`,
                      h3: {
                        color: colors.white,
                      },
                    },
                  })}
                >
                  <Link
                    to={card.internalLink?.fields?.path}
                    css={css({
                      display: `flex`,
                      justifyContent: `center`,
                      padding: `${space[4]}px`,
                      textDecoration: `none`,
                    })}
                  >
                    <div>
                      <h3
                        css={css({
                          fontWeight: `bold`,
                          color: colors.base,
                        })}
                      >
                        {card.title}
                      </h3>
                    </div>
                  </Link>
                </li>
              )
            })}
          </ul>
        )}
      </Container>
    </section>
  )
}