import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const EmbededMap = styled.iframe`
  height: 400px;
  width: 100%;
`

const GoogleMapEmbed = ({ src, ...rest }) => {
  return (
    <EmbededMap
      id="gmap_canvas"
      src={src}
      frameBorder="0"
      scrolling="no"
      marginHeight="0"
      marginWidth="0"
      {...rest}
    />
  )
}

export default GoogleMapEmbed

GoogleMapEmbed.propTypes = {
  src: PropTypes.string.isRequired,
}