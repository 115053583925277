import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'styled-components'
import Img from 'gatsby-image'
import { space, fontSizes, breakpoints, mediaQueries, fontFamily, lineHeights } from '../../utils/tokens'
import { ButtonSelector } from '../ButtonSelector'

export const AlternatingRowsSection = (props) => {
  const { section } = props;

  return (
    <section
      css={css({
        padding: `${space[4]}px 0`,
        [mediaQueries.lg]: {
          padding: `${space[5]}px 0`,
        },
      })}
    >
      <div
        css={css({
          [mediaQueries.xxxl]: {
            maxWidth: breakpoints.xxl,
            margin: `0 auto`,
          },
        })}
      >
        {section.title && (
          <div
            css={css({
              padding: `0 ${space[4]}px`,
              [mediaQueries.lg]: {
                maxWidth: breakpoints.xl,
                margin: `0 auto`,
                padding: 0,
              },
            })}
          >
            <h2
              css={css({
                fontWeight: `bold`,
                fontSize: fontSizes[3],
                marginBottom: `${space[4]}px`,
                [mediaQueries.lg]: {
                  textAlign: `center`,
                  fontSize: fontSizes[4],
                  marginBottom: `${space[5]}px`,
                },
              })}
            >
              {section.title}
            </h2>
          </div>
        )}
        <div
          css={css({
            display: `grid`,
          })}
        >
          {section.cards &&
            section.cards.map((card, id) => {
              return (
                <div
                  key={id}
                  css={css({
                    display: `flex`,
                    flexDirection: `column-reverse`,
                    ':nth-child(odd) .image-wrapper': {
                      padding: `0 ${space[4]}px`,
                    },
                    [mediaQueries.lg]: {
                      display: `flex`,
                      flexDirection: `row`,
                      minHeight: `30vh`,
                      ':nth-child(even)': {
                        flexDirection: `row-reverse`,
                      },
                      ':nth-child(odd) .image-wrapper': {
                        padding: 0,
                      },
                    },
                  })}
                >
                  <div
                    css={css({
                      padding: `${space[5]}px ${space[4]}px`,
                      [mediaQueries.lg]: {
                        display: `flex`,
                        justifyContent: `center`,
                        alignItems: `center`,
                        width: `50%`,
                        padding: `0 ${space[4]}px`,
                      },
                    })}
                  >
                    <div>
                      <h3
                        css={css({
                          fontSize: fontSizes[2],
                          fontWeight: `bold`,
                          marginBottom: `${space[3]}px`,
                          [mediaQueries.lg]: {
                            fontSize: fontSizes[3],
                            marginBottom: 0,
                          },
                        })}
                      >
                        {card.title}
                      </h3>
                      {card.description && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: card.description.childMarkdownRemark.html,
                          }}
                          css={css({
                            marginTop: `${space[3]}px`,
                            maxWidth: breakpoints.sm,
                            fontFamily: fontFamily.body,
                            lineHeight: lineHeights.body,
                            p: {
                              margin: `${space[3]}px 0`,
                            },
                          })}
                        />
                      )}
                      {card.buttons && (
                        <div css={{
                          maxWidth: breakpoints.xs,
                          marginTop: `${space[5]}px`,
                        }}>
                          {card.buttons.map(button => {
                            return (
                              <div key={button.id}>
                                <ButtonSelector button={button} />
                              </div>
                            )
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                  {card.media && (
                    <div
                      className="image-wrapper"
                      css={css({
                        padding: `0 !important`,
                        [mediaQueries.lg]: {
                          display: `flex`,
                          flexDirection: `column`,
                          justifyContent: `center`,
                          width: `50%`,
                        },
                      })}
                    >
                      <Img fluid={card.media.fluid} alt={card.media.title} />
                    </div>
                  )}
                </div>
              )
            })}
        </div>
      </div>
    </section>
  )
}

AlternatingRowsSection.propTypes = {
  section: PropTypes.shape({
    title: PropTypes.string,

  })
}