import React from 'react'
import Container from '../Container'
import { css } from 'styled-components'
import { mediaQueries, colors, fontSizes, space, palette, breakpoints, fontFamily } from '../../utils/tokens'

export const FeatureListSection = ({ subTitle, title, cards }) => {
  return (
    <section
      css={css({
        backgroundColor: colors.lightBase,
        padding: `${space[5]}px ${space[4]}px`,
        [mediaQueries.lg]: {
          padding: `${space[6]}px`,
        },
      })}
    >
      <Container>
        <div
          css={css({
            display: `flex`,
            flexDirection: `column`,
            alignItems: `center`,
            marginBottom: `${space[4]}px`,
            [mediaQueries.lg]: {
              marginBottom: `${space[5]}px`,
            },
          })}
        >
          <h2
            css={css({
              color: colors.white,
              fontWeight: `bold`,
              marginBottom: `${space[3]}px`,
              [mediaQueries.lg]: {
                fontSize: fontSizes[4],
              },
            })}
          >
            {title}
          </h2>
          {subTitle && (
            <p
              css={css({
                color: palette.grey[40],
                maxWidth: breakpoints.sm,
                margin: `0 auto`,
                fontFamily: fontFamily.body,
                lineHeight: `1.2`,
              })}
            >
              {subTitle}
            </p>
          )}
        </div>
        <ul
          css={css({
            listStyle: `none`,
            display: `flex`,
            flexFlow: `wrap`,
            flexDirection: `column`,
            [mediaQueries.lg]: {
              flexDirection: `row`,
            },
          })}
        >
          {cards &&
            cards.map(card => {
              return (
                <li
                  key={card.id}
                  css={css({
                    marginBottom: `${space[4]}px`,
                    [mediaQueries.lg]: {
                      width: `33%`,
                      padding: `${space[4]}px`,
                      marginBottom: 0,
                    },
                  })}
                >
                  <div
                    css={css({
                      marginBottom: `${space[2]}px`,
                      display: `flex`,
                      alignItems: `center`,
                    })}
                  >
                    {card.media && (
                      <div
                        css={css({
                          height: fontSizes[4],
                          width: fontSizes[4],
                          marginRight: `${space[3]}px`,
                        })}
                      >
                        <div
                          css={css({
                            width: `100%`,
                            height: `100%`,
                            mask: `url(${card.media.file.url}) no-repeat center`,
                            backgroundColor: colors.primaryBrand,
                          })}
                        />
                      </div>
                    )}
                    <span
                      css={css({
                        color: colors.white,
                        fontWeight: `bold`,
                        fontSize: fontSizes[2],
                      })}
                    >
                      {card.title}
                    </span>
                  </div>
                  {card.description && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: card.description.childMarkdownRemark.html,
                      }}
                      css={css({
                        color: palette.grey[40],
                        fontSize: fontSizes[2],
                      })}
                    />
                  )}
                </li>
              )
            })}
        </ul>
      </Container>
    </section>
  )
}