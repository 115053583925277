import React from 'react'
import { css } from 'styled-components'
import { colors, space } from '../utils/tokens'

export const TitleLineBreak = ({ className, center }) => (
    <hr
      css={css({
        height: `8px`,
        backgroundColor: colors.primaryBrand,
        maxWidth: `64px`,
        marginTop: `${space[3]}px`,
        marginBottom: `${space[4]}px`,
        marginLeft: center ? `auto` : 0,
        marginRight: center ? `auto` : 0,
      })}
      className={className}
    />
  )