import React from 'react'
import Img from 'gatsby-image'
import { css } from 'styled-components'

import {
  mediaQueries,
  space,
  letterSpacings,
  breakpoints,
  fontSizes,
  boxShadows,
  fontFamily,
  colors
} from '../../utils/tokens'
import { QuoteButton } from '../Button';

const PricingSection = (props) => {
  const { section, ...rest } = props
  return (
    <section
      css={css({
        padding: `${space[4]}px 0`,
        backgroundColor: colors.mute,
        [mediaQueries.lg]: {
          padding: `${space[5]}px 0`,
        },
      })}
      {...rest}
    >
      <div
        css={css({
          maxWidth: breakpoints.xxl,
          margin: `auto`,
          display: `grid`,
          gridGap: `${space[4]}px`,
          [mediaQueries.md]: {
            display: `flex`,
          },
        })}
      >
        {section.image && (
          <Img
            fluid={section.image.fluid}
            alt={section.image.title}
            css={css({
              [mediaQueries.md]: {
                flex: `0 0 50%`,
              },
              [mediaQueries.lg]: {
                flex: `0 0 60%`,
              },
            })}
          />
        )}
        <div
          css={css({
            justifySelf: `center`,
            width: `100%`,
            padding: `0 ${space[4]}px`,
            [mediaQueries.md]: {
              padding: `0 ${space[4]}px`,
            },
            [mediaQueries.xxl]: {
              padding: `0 ${space[5]}px`,
            },
          })}
        >
          <h2
            css={css({
              fontWeight: `bold`,
              fontSize: fontSizes[4],
              textAlign: `center`,
              marginBottom: `${space[4]}px`,
            })}
          >
            Pricing
          </h2>
          <div
            css={css({
              display: `grid`,
              gridGap: `${space[4]}px`,
              [mediaQueries.lg]: {
                display: `flex`,
                justifyContent: `center`,
              },
            })}
          >
            {section.cards &&
              section.cards.map(card => {
                return (
                  <div
                    key={card.id}
                    css={css({
                      boxShadow: boxShadows.card,
                      backgroundColor: colors.white,
                      padding: `${space[5]}px ${space[4]}px`,
                      borderRadius: `16px`,
                      maxWidth: `400px`,
                      margin: `0 ${space[3]}px`,
                      display: `flex`,
                      flexDirection: `column`,
                    })}
                  >
                    <h3
                      css={css({
                        fontSize: fontSizes[3],
                        lineHeight: `1`,
                        fontWeight: `bold`,
                        textAlign: `center`,
                        marginBottom: `${space[1]}px`,
                      })}
                    >
                      {card.title}
                    </h3>
                    <p
                      css={css({
                        fontSize: fontSizes[2],
                        fontWeight: `bold`,
                        color: colors.success,
                        textAlign: `center`,
                        marginBottom: `${space[4]}px`,
                      })}
                    >
                      {card.subtitle}
                    </p>
                    {card.description && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: card.description.childMarkdownRemark.html,
                        }}
                        css={css({
                          display: `flex`,
                          flexDirection: `column`,
                          alignItems: `center`,
                          marginBottom: `${space[5]}px`,
                          'h1, h2, h3, h4, h5': {
                            fontSize: fontSizes[2],
                            marginBottom: `${space[3]}px`,
                          },
                          ul: {
                            listStyle: `none`,
                          },
                          li: {
                            marginBottom: `${space[3]}px`,
                            fontFamily: fontFamily.body,
                            display: `flex`,
                            '&::before': {
                              content: '"+"',
                              marginRight: `${space[2]}px`,
                              fontWeight: `bold`,
                            },
                          },
                        })}
                      />
                    )}
                    {card.media && (
                      <img
                        src={card.media.file.url}
                        alt={card.media.title}
                        css={css({
                          width: `75%`,
                          margin: `auto`,
                        })}
                      />
                    )}
                    {card.externalLink && (
                      <QuoteButton
                        dark
                        url={card.externalLink}
                        css={css({
                          marginTop: `${space[4]}px`,
                          maxWidth: `256px`,
                          marginLeft: `auto`,
                          marginRight: `auto`,
                          backgroundColor: colors.danger,
                          border: `none`,
                          ':hover': {
                            color: `white`,
                            transform: `scale(1.1)`,
                          },
                        })}
                      />
                    )}
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default PricingSection