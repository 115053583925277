import React from 'react'
import Img from 'gatsby-image'
import Container from '../Container'
import { css } from 'styled-components'
import { space, mediaQueries, fontSizes, boxShadows } from '../../utils/tokens'

const MaterialsListSection = ({
  materials,
  section
}) => {

  const title = section && section.title || `Materials`
  return (
    <section>
      <Container>
        <div
          css={css({
            padding: `${space[5]}px ${space[4]}px`,
            [mediaQueries.xxl]: {
              padding: `${space[5]}px 0`,
            },
          })}
        >
          <h2
            css={css({
              fontSize: fontSizes[3],
              fontWeight: `bold`,
              marginBottom: `${space[4]}px`,
              [mediaQueries.xxl]: {
                fontSize: fontSizes[4],
                marginBottom: `${space[4]}px`,
              },
            })}
          >
            {title}
          </h2>
          <div
            css={css({
              display: `grid`,
              gridGap: `${space[3]}px`,
              [mediaQueries.lg]: {
                gridTemplateColumns: `repeat(3, 1fr)`,
                gridGap: `${space[4]}px`,
              },
            })}
          >
            {materials &&
              materials.map((material, id) => {
                return (
                  <div
                    key={id}
                    css={css({
                      borderRadius: `8px`,
                      boxShadow: boxShadows.card,
                      overflow: `hidden`,
                    })}
                  >
                    {material.coverPhoto && (
                      <Img
                        fluid={material.coverPhoto.fluid}
                        alt={material.title}
                      />
                    )}
                    <div
                      css={css({
                        padding: `${space[4]}px`,
                      })}
                    >
                      <h5
                        css={css({
                          fontSize: fontSizes[3],
                          fontWeight: `bold`,
                          marginBottom: `${space[3]}px`,
                        })}
                      >
                        {material.name}
                      </h5>
                      <hr />
                      {material.description && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              material.description.childMarkdownRemark.html,
                          }}
                          css={css({
                            fontSize: fontSizes[2],
                          })}
                        />
                      )}
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </Container>
    </section>
  )
}

export default MaterialsListSection