import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'styled-components'

import Container from '../../components/Container'

import {
  fontSizes,
  space,
  mediaQueries,
  colors,
} from '../../utils/tokens'

import { sectionThemeStyle, text } from '../../utils/styles'
import { TitleLineBreak } from '../TitleLineBreak'

const WhyUsSection = (props) => {
  const { cards, title, theme = 'light' } = props

  return (
    <section
      css={css({
        padding: `${space[5]}px ${space[4]}px`,
        [mediaQueries.lg]: {
          padding: `80px ${space[5]}px`,
        },
      })}
    >
      <div
        css={css({
          ...sectionThemeStyle[theme],
        })}
      >
        <Container>
          {title && (
            <div>
              <h2
                css={css({
                  ...text.sectionHeading,
                })}
              >
                {title}
              </h2>
              <TitleLineBreak center />
            </div>
          )}
          <div
            css={css({
              marginTop: `${space[4]}px`,
              [mediaQueries.md]: {
                marginTop: `${space[5]}px`,
                padding: `0 ${space[5]}px`,
              },
              [mediaQueries.lg]: {
                display: `grid`,
                gridTemplateColumns: `1fr 1fr 1fr`,
                gridGap: `${space[5]}px`,
                padding: `0 ${space[5]}px`,
              },
            })}
          >
            {cards &&
              cards.map((card, id) => {
                return (
                  <div
                    key={id}
                    css={css({
                      marginBottom: `${space[4]}px`,
                      [mediaQueries.lg]: {
                        marginBottom: 0,
                      },
                    })}
                  >
                    {card.media && (
                      <div
                        css={css({
                          color: colors.primaryBrand,
                          fontSize: fontSizes[4],
                          marginBottom: `${space[2]}px`,
                        })}
                      >
                        <img
                          css={css({
                            width: `48px`,
                            height: `48px`,
                          })}
                          src={card.media?.file?.url}
                        />
                      </div>
                    )}
                    <div
                      css={css({
                        marginBottom: `${space[3]}px`,
                        display: `flex`,
                        alignItems: `center`,
                      })}
                    >
                      <h3
                        css={css({
                          fontSize: fontSizes[2],
                          fontWeight: `bold`,
                        })}
                      >
                        {card.title}
                      </h3>
                    </div>
                    {card.description && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: card.description.childMarkdownRemark.html,
                        }}
                        css={css({
                          lineHeight: `1.5`,
                        })}
                      />
                    )}
                  </div>
                )
              })}
          </div>
        </Container>
      </div>
    </section>
  )
}

export default WhyUsSection

WhyUsSection.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.object).isRequired,
}