import React from 'react'
import { css } from 'styled-components'
import Img from 'gatsby-image'
import RichArticle from '../RichArticle'

import { space, mediaQueries, breakpoints, fontSizes, fontFamily, lineHeights } from '../../utils/tokens'

export const ContentWithPhotoSection = ({ section }) => {

  const { properties } = section

  const turnPropertiesIntoPropsObject = properties => {
    return properties.reduce((acc, cv) => {
      const key = cv.key
      const value = cv.value

      if (key && value) {
        return { ...acc, [key]: value }
      }

      return acc
    }, {})
  }

  let sectionProps = {}

  if(properties) {
    sectionProps = turnPropertiesIntoPropsObject(properties)
  }

  const { reversed } = sectionProps



  return (
    <section
      css={css({
        [mediaQueries.lg]: {
          maxWidth: breakpoints.xxl,
          margin: `auto`,
        },
      })}
    >
      <div
        css={css({
          display: `flex`,
          flexDirection: `column-reverse`,
          [mediaQueries.lg]: {
            display: `grid`,
            gridTemplateColumns: `1fr 1fr`,
            alignItems: `center`,
          },
        })}
      >
        <div
          css={css({
            display: `flex`,
            flexDirection: `column`,
            justifyContent: `center`,
            alignItems: `center`,
            gridColumn: reversed ? 2 : 1,
          })}
        >
          <div
            css={css({
              padding: `${space[5]}px ${space[4]}px`,
              [mediaQueries.lg]: {
                padding: `${space[6]}px ${space[6]}px`,
              },
            })}
          >
            <h2
              css={css({
                fontWeight: `bold`,
                fontSize: fontSizes[3],
                marginBottom: `${space[4]}px`,
                [mediaQueries.lg]: {
                  fontSize: fontSizes[4],
                },
              })}
            >
              {section.title}
            </h2>
            {section.richTextBody && (
              <RichArticle body={section.richTextBody} />
            )}
          </div>
        </div>
        {section.image && (
          <Img
            fluid={section.image.fluid}
            alt={section.image.title}
            css={css({
              gridColumn: reversed ? 1 : 2,
              gridRow: 1,
            })}
          />
        )}
      </div>
    </section>
  )
}